.card--grid--stats div {
    margin-bottom: 4rem;
}

.card--grid--stats table {
    min-width: auto;
}

@media screen and (min-width: 720px) {
    .card--grid--stats {
        grid-template-columns: 1fr 1fr;
        display: grid;
        grid-gap: 3rem;
    }

    .card--grid--stats div {
        margin-bottom: 0;
    }

    .card--grid--stats div.u-margin-b {
        margin-bottom: 4rem;
    }
}