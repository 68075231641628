.seasonfilter {
    list-style-type: none;
    padding: 0;
    margin-bottom: 50px;
}

.seasonfilter--button {
    padding: 7px 15px;
    background-color: var(--color-gray);
    color: white;
    outline: none;
    border: none;
    width: 100%;
    height: 35px;
    margin-bottom: 5px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 1rem;
}

.seasonfilter--button:hover,
.seasonfilter--button.active {
    background-color: var(--color-green);
    cursor: pointer;
}

.seasonfilter--button:active {
    border-bottom: 2px solid var(--color-gray);
}

@media screen and (min-width: 700px) {
    .seasonfilter {
        display: flex;
        justify-content: space-between;
        width: 575px;
    }
}