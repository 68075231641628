.about {
    max-width: 60ch;
    margin: auto;
    text-align: center;
    margin-top: 4rem;
    padding: 0 2rem;
}

.about p {
    margin-bottom: 1.2rem;
    line-height: 1.6rem;
}