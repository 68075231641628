.filter--type button {
    padding: 7px 15px;
    background-color: var(--color-gray);
    color: white;
    outline: none;
    border: none;
    height: 35px;
    margin-right: 10px;
    border-radius: 3px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    font-size: small;
}

.filter--type button:hover {
    cursor: pointer;
}

.filter--type button.active {
    background-color: var(--color-green);
}