.nav-zlt {
    background-color: var(--color-black);
    height: 200px;
    padding: 20px;
    font-size: 1rem;
}

.nav-zlt--logo {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 40px;
    color: white;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.nav-zlt--items {
    width: 100%;
    text-transform: lowercase;
    letter-spacing: .08rem;
}

.nav-zlt--items a.active {
    text-decoration: underline;
}

/* .nav-zlt--items a span {
    background: var(--color-pink);
    border-radius: 3px;
    color: white;
    font-weight: bold;
    padding: 2px;
} */

.nav-zlt--button {
    border: 2px solid white;
    color: white;
    padding: 8px 5px;
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 1rem;
    font-weight: bold;
    height: 17px;
    display: inline-block;
}

.petjeaf--button {
    margin-right: 10px;
}

.petjeaf--button img {
    height: 20px;
    vertical-align: middle;
}

.nav-zlt--button:hover,
.reverse {
    background: white;
    color: var(--color-gray);
    text-decoration: none;
}

.reverse-pink {
    background: var(--color-green);
    color: white;
    text-decoration: none;
    border-color: var(--color-green);
}

.nav-zlt--items div:first-child a {
    margin-right: 20px;
}

.nav-zlt--items div:first-child {
    flex-grow: 1;
    height: 50px;
    margin-bottom: 10px;
}

@media screen and (min-width: 975px) {
    .nav-zlt {
        display: flex;
        align-items: center;
        height: 40px;
    }

    .nav-zlt--logo {
        margin-bottom: 0;
    }

    .nav-zlt--items {
        display: flex;
        width: 100%;
        align-items: center;
    }

    .nav-zlt--items div:first-child {
        height: auto;
        margin-bottom: 0;
    }
}