.search--input {
    height: 30px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
    border-radius: 3px;
    border: 1px solid var(--color-gray);
    padding: 5px;
}

label {
    text-transform: uppercase;
    font-size: small;
    font-weight: bold;
}

.sortable:hover {
    cursor: pointer;
    text-decoration: underline;
}