.app--container {
  margin: 20px;
}

.card--grid {
  margin-bottom: 4rem;
}

.episodetable {
  min-width: 1000px;
}

.min-height-400 {
  height: 400px;
}

.final-episode--type {
  background: var(--color-green);
  border-radius: 5px;
  padding: 2px;
  font-size: small;
  color: white;
  position: relative;
  top: -10px;
  left: 5px;
  text-transform: uppercase;
}

.final-episode--type.lol {
  background: var(--color-pink);
}

.episodecontainer {
  margin-top: 2rem;
}

.episodecontainer h3 {
  margin-left: 10px;
  margin-bottom: 20px;
}

.spotify--label {
  background: #1bb251;
  border-radius: 5px;
  padding: 2px;
  font-size: small;
  color: white;
  margin-left: 5px;
  text-transform: uppercase;
}

.spotify--label:hover {
  background: #16813c;
}

.episode--link {
  margin-left: 5px;
  position: relative;
  top: -10px;
}

.episode--link a:hover {
  text-decoration: none;
}

.artist_flag {
  height: 16px;
  width: 16px;
  vertical-align: middle;
  top: -1px;
  border-radius: 5px;
  position: relative;
}


@media screen and (min-width: 720px) {
  .card--grid {
    grid-template-columns: 1fr 3fr;
    display: grid;
    grid-gap: 3rem;
  }
}