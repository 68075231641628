/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}


table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-black: #29272C;
  --color-gray: #565459;
  --color-lightgray: #f2f4f6;
  --color-pink: pink;
  --color-green: #00C9A4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-black);
}

h1 {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 1.6rem 0;
}

h2 {
  font-size: 1.4rem;
  margin: 1.2rem 0;
  font-weight: bold;
}

h3 {
  font-size: 1.1rem;
  font-weight: bold;
}

a {
  color: var(--color-green);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  margin-bottom: 1.2rem;
}

table {
  background: white;
  border-collapse: collapse;
  width: 100%;
  min-width: 1000px;
  font-size: 0.9rem;
  line-height: 1.25rem;
  table-layout: fixed;
}

table thead th,
table tfoot th {
  color: white;
  background: var(--color-lightgray);
  text-align: left;
  text-transform: uppercase;
  letter-spacing: .05em;
  border-bottom-width: 2px;
  color: var(--color-gray);
  font-weight: bold;
}

table th,
table td {
  padding: 0.75rem;
  border-bottom: 1px solid lightgrey;
}

table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tablecontainer {
  overflow: hidden;
  overflow-x: scroll;
}

@media screen and (min-width: 700px) {
  table {
    min-width: auto;
  }
}